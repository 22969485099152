
import {Component, Vue, Watch} from 'vue-property-decorator'
import {VNode} from 'vue'
import {UndoSystem} from '@/domain/model/types'
import {notificationsStore} from '@/store/modules/notifications'
import UndoMessage from '@/components/custom/UndoMessage.vue'
import {Action} from "vuex-class";
import {mixins} from "vue-class-component";
import Notifications from "@/components/mixins/Notifications";

@Component({
  name: 'gp-undo',
  components: {UndoMessage}
})
export default class Undo extends mixins(Notifications) {
  appRoot: HTMLElement | null = null
  children: any[] = []

  @Action rejectRequest
  @Action forwardCase
  @Action closeConversation

  get undo() {
    return notificationsStore.undo
  }

  get processed() {
    return notificationsStore.undoProcessed
  }

  @Watch('undo')
  onChangUndoSystem(after: UndoSystem[]) {
    const newUndo: UndoSystem[] = after.filter((item) => this.processed.indexOf(item.id) === -1)
    newUndo.forEach((undo) => {
      notificationsStore.addUndoProcessed(undo.id)
      const undoMessage = Vue.extend(UndoMessage)
      const child = new undoMessage({ propsData: undo}).$mount()
      child.$on('onCloseUndo', this.onCloseUndo)
      this.children.unshift(child)
      this.appRoot?.appendChild(child.$el)
      this.childOrder()
    })
  }

  onCloseUndo(id: string) {
    notificationsStore.unsetUndo(id)
    this.dismissChild(id)
  }

  childById(id: string) {
    return this.children.find(child => child.$options.propsData.id === id)
  }

  childOrder() {
    this.children.forEach((child, indx) => {
      child.$props.offset = indx;
    })
  }

  mounted() {
    this.appRoot = document.getElementById('app')
  }

  dismissChild(id: string) {
    const child = this.childById(id)
    if (child) {
      this.appRoot?.removeChild(child.$el)
      child.$off('onCloseUndo')
      child.$destroy()
      this.children.splice(this.children.findIndex((item) => item.$options.propsData.id === id), 1)
    }
    this.childOrder()
  }

  render(h): VNode {
    return h('span')
  }
}
