/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'minimized': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.369.392a11.36 11.36 0 015.848 3.758 9.088 9.088 0 013.14-.592l.39.001a.5.5 0 01.416.761c-1.073 1.752-1.571 3.48-1.495 5.186.269 1.57.21 3.223-.23 4.863-1.63 6.088-7.889 9.7-13.977 8.07-6.088-1.632-9.7-7.89-8.07-13.978 1.632-6.088 7.89-9.7 13.978-8.07zM6.807 7.235a2.746 2.746 0 00-2.139 4.468 3.248 3.248 0 012.23-.88c.81 0 1.55.295 2.121.784a2.746 2.746 0 00-2.212-4.372zm9.216 0a2.746 2.746 0 00-2.181 4.415 3.238 3.238 0 012.169-.828c.84 0 1.605.317 2.183.839a2.746 2.746 0 00-2.171-4.426z" _fill="#FFF" fill-rule="nonzero"/>'
  }
})
