




import {Component, Prop, Vue} from 'vue-property-decorator'
import {tourStore} from "@/store/modules/tour";
@Component({
  name: 'tour-background'
})
export default class TourBackground extends Vue {
  @Prop() position: any

  get relativeObj() {
    return tourStore.tourRelativeObj
  }

  get selectedObj() {
    return tourStore.tourSelectedObj
  }

  get backgroundStyle() {
    const styleList: string[] = []
    for (const [key, val] of Object.entries(this.position)) {
      if (typeof(val) === 'number') {
        styleList.push(`${key}: ${val}px`)
      } else if (typeof val === 'object' && val !== null && !Array.isArray(val)) {
        for (const [key1, val1] of Object.entries(val)) {
          if (key1.charAt(0) === '_' && this.selectedObj) {
            let dest = Math.round(this.selectedObj[key1.substring(1)]) + val1
            styleList.push(`${key}: ${dest}px`)
          } else if (this.relativeObj) {
            let dest = Math.round(this.relativeObj[key1]) + val1
            styleList.push(`${key}: ${dest}px`)
          }
        }
      } else if (typeof val === 'string' || val instanceof String) {
        styleList.push(`${key}: ${val}`)
      }
    }
    return styleList.join(';')
  }
}
