











import {Component, Prop, Vue} from 'vue-property-decorator';
import {notificationsStore} from "@/store/modules/notifications";

@Component
export default class UndoMessage extends Vue {
  @Prop() id!: string
  @Prop() type!: string
  @Prop() text!: string
  @Prop() data!: any
  @Prop() callback!: any
  @Prop() timeout!: number
  @Prop() offset: number = 0

  width: null | number = null
  counter: number = 5
  interval: any | number

  get classUndo() {
    return 'undo undo-' + this.type
  }

  get circleStyle() {
    return `animation: countdown ${this.timeout}s linear infinite forwards;`
  }


  get style() {
    let styleData = { bottom: `${15 + 64 * this.offset}px` }
    if  (this.width) {
      styleData['width'] = `${this.width}px`
      styleData['margin-left'] = `-${this.width/2}px`
    }
    return styleData
  }

  onUndo() {
    clearInterval(this.interval)
    this.$emit('onCloseUndo', this.id)
  }

  created() {
    this.counter = this.timeout
    this.interval = window.setInterval(() => {
      this.counter = this.counter - 1
      if (this.counter <= 0) {
        clearInterval(this.interval)
        this.callback(this.data)
        this.$emit('onCloseUndo', this.id)
        return
      }
    }, 1000)
  }

  mounted() {
    this.$nextTick(function () {
      const containerElement = this.$refs.container as HTMLElement
      if (containerElement) {
        this.width = Math.ceil(containerElement.getBoundingClientRect().width)
      }
    })
  }
}
